import React from "react"
// import { Fade } from "react-reveal"

import { CardWrapper, Status, StatusBox, TextAndContent } from "./styles"

export const Card = ({
  title,
  description,
  delay,
  padding,
  children,
  status,
  link,
}) => {
  return (
    // <Fade bottom delay={Number(delay)}>
    <CardWrapper key={link} padding={padding}>
      {children}

      <Status>
        {status?.map((item, index) => (
          // <Fade key={item.id} bottom delay={Number(index * 30)}>

          <StatusBox key={index} itemBackground={item.background}>
            {item.name}
          </StatusBox>

          // </Fade>
        ))}
      </Status>

      {title && (
        <TextAndContent>
          {/* <Fade bottom delay="100"> */}
          <h1>{title}</h1>
          {/* </Fade> */}
          {/* 
            <Fade bottom delay="120"> */}
          <p>{description}</p>
          {/* </Fade> */}
        </TextAndContent>
      )}

      {/* <Fade bottom delay="150"> */}
      <a rel="noopener noreferrer" target="_blank" href={link}>
        Live link &#10138;
      </a>
      {/* </Fade> */}
    </CardWrapper>
    // </Fade>
  )
}
