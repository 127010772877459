import styled from "styled-components"

export const CardWrapper = styled.div`
  padding: ${({ padding }) => padding || "25px"};
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); */
  border: ${({ theme }) =>
    theme.name === "light"
      ? "1px solid rgba(255, 255, 255, 0.3)"
      : "1px solid rgb(255 255 255 / 29%)"};
  height: 100%;
  width: 100%;
  transition: all 0.3s linear;
  max-width: 523px;

  > a {
    background: ${({ theme }) => theme.background};
    padding: 6px 15px;
    border-radius: 7px;
    color: ${({ theme }) => theme.backgroundText};
    font-size: 12px;
    margin-right: 10px;
  }

  .middle {
    height: 100%;
    display: flex;
    align-items: center;

    > h2 {
      font-size: 40px;
      color: var(--white);
      text-align: center;
      -webkit-text-fill-color: #0000;
      background: linear-gradient(180deg, #feac0e, #da3654 70%);
      -webkit-background-clip: text;
      background-clip: text;
      background-image: linear-gradient(90deg, #f931c6c2 0, #46c5b3);
    }
  }

  &:hover {
    transform: translateY(-10px);
  }
`

export const TextAndContent = styled.div`
  margin-bottom: 30px;

  h1 {
    font-size: 21px;
    margin: 30px 0 20px 0;
    font-weight: var(--semiBold);
    color: ${({ theme }) => theme.text};
  }

  p {
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.cardP};
  }
`
export const Status = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`

export const StatusBox = styled.div`
  background: ${({ itemBackground }) => itemBackground};
  padding: 6px 15px;
  border-radius: 40px;
  color: #fff;
  font-size: 12px;
`
