import styled from "styled-components"

export const FeaturesWrapper = styled.section`
  padding-top: ${({ theme }) => (theme.name === "light" ? "0" : "70px")};
  padding-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-image: ${({ theme }) => `url(${theme.heroImage2})`};
  background-color: ${({ theme }) => theme.body};
  border-top: ${({ theme }) =>
    theme.name === "light" ? "0" : "1px solid #585858"};
  position: relative;
`

export const MainContent = styled.div`
  /* background-image: url("./services-back2.png"); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  border-radius: 16px;
  padding: 0 24px;

  @media (max-width: 992px) {
    padding: 0;
  }
`
export const Heading = styled.div`
  display: flex;
  position: absolute;
  top: ${({ theme }) => (theme.name === "light" ? "-80px" : "60px")};

  h6 {
    background: ${({ theme }) => theme.background};
    padding: 6px 15px;
    border-radius: 40px;
    color: ${({ theme }) => theme.backgroundText};
    font-size: 12px;
    font-weight: 400;
  }
`

export const TextAndContent = styled.div`
  max-width: 515px;
  padding: ${({ padding }) => padding};

  span {
    background: ${({ theme }) => theme.background};
    padding: 6px 15px;
    border-radius: 40px;
    color: ${({ theme }) => theme.backgroundText};
    font-size: 12px;
  }

  > h1 {
    font-size: 30px;
    margin: 60px 0 10px 0;
    font-weight: var(--semiBold);
  }

  > p {
    font-size: 15px;
    line-height: 20px;
  }
`

export const CardWrapper = styled.div`
  display: grid;
  margin: 60px 0 80px 0;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px 0;
`

export const Button = styled.span`
  background: ${({ theme }) => theme.background};
  padding: 6px 15px;
  border-radius: 40px;
  color: ${({ theme }) => theme.backgroundText};
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
`

export const Upper = styled.div`
  background-image: linear-gradient(
    180deg,
    hsl(0deg 0% 100%),
    hsl(0deg 0% 100% / 34%) 42%,
    #fff0
  );
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  position: absolute;
`
