import React from "react"
import { ThemeProvider } from "styled-components"
import Footer from "./Footer"
// import { Fade } from "react-reveal"

import { darkTheme } from "../GlobalStyles"
import Nav from "./Nav"

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Nav />

      {children}
      <Footer />
    </ThemeProvider>
  )
}
