/* eslint-disable react/no-unescaped-entities */
import React from "react"
import styled from "styled-components"

import { MainWrapper } from "components"
import { Heading } from "../Projects/styles"
import CodeBack from "../../images/b.png"
import CodeBack2 from "../../images/b-2.png"

const ServicesWrapper = styled.section`
  padding-top: 30px;
  background-color: #000;
  position: relative;
  padding-bottom: 100px;
`

const ServicesContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 70px;

  @media (max-width: 992px) {
    display: block;
  }
`

const ServicesBox = styled.div`
  background-image: ${({ first }) =>
    first ? `url(${CodeBack})` : `url(${CodeBack2})`};
  background-repeat: no-repeat;
  color: white;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: 60px 50px;
  min-height: 300px;
  border-radius: 30px;
  border: 1px solid rgb(255 255 255 / 29%);
  transition: all 0.3s linear;

  @media (max-width: 992px) {
    margin: 30px 0;
  }

  :hover {
    transform: translateY(-10px);
  }

  div {
    display: flex;
    justify-content: center;
  }

  h2 {
    margin-top: 20px;
    text-align: center;
    line-height: 50px;
  }

  p {
    line-height: 30px;
    margin-top: 20px;
  }

  .names {
    margin-top: 30px;
  }
`

export const Services = () => {
  return (
    <ServicesWrapper id="services">
      <MainWrapper>
        <Heading>
          <h6>My services</h6>
        </Heading>
        <ServicesContentWrapper>
          <ServicesBox first={true}>
            <h2>Develop.</h2>
            <div>
              <p>
                + Websites & Apps
                <br />+ Ecommerce
                <br />+ Shopify
                <br />+ Content Management Systems
                <br />+ Sales Funnel
              </p>
            </div>
            {/* <div>
              <p className="names">
                Things I enjoy designing:
                <br />
                UX, UI, Web, Apps, Logos
              </p>
            </div> */}
          </ServicesBox>
          <ServicesBox>
            <h2>Design.</h2>
            <div>
              <p>
                + UI . UX Design
                <br />+ Web Design
                <br />+ App Design
                <br />+ Branding
                <br />+ Product Design
              </p>
            </div>
          </ServicesBox>
        </ServicesContentWrapper>
      </MainWrapper>
    </ServicesWrapper>
  )
}
