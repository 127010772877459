import styled from "styled-components"

export const SimpleButton = styled.a`
  color: ${({ color }) => color || "var(--black)"};
  background: ${({ background }) => background || "var(--thaiChili)"};
  padding: ${({ padding }) => padding || "7px 15px"};
  font-size: ${({ fontSize }) => fontSize || "15px"};
  margin: ${({ margin }) => margin || "0"};
  border: ${({ border }) => border || "0"};
  border-radius: var(--buttonBorderRadious);
  cursor: pointer;
  transition: 0.3s;
  font-family: "Oswald", sans-serif;
  height: ${({ height }) => height || "inherit"};

  &:hover {
    background: var(--secondaryColor);
    color: var(--white);
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`

export const OnClickButton = styled.button`
  background: ${({ theme }) => theme.background};
  padding: 6px 15px;
  border-radius: 40px;
  color: ${({ theme }) => theme.backgroundText};
  outline: 0;
  border: 0;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  cursor: pointer;

  &:hover {
    transform: translateX("-10px");
  }
`
