/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react"
// import { Fade } from "react-reveal"
import { StaticImage } from "gatsby-plugin-image"
import { useTheme } from "styled-components"

import { MainWrapper } from "components"
import {
  CardWrapper,
  FeaturesWrapper,
  MainContent,
  Button,
  ButtonWrap,
  Upper,
  Heading,
} from "./styles"
import { Card } from "./Card"
// import Footer from "../Layout/Footer"

export const Projects = () => {
  const [load, setLoad] = useState(false)
  const [load1, setLoad1] = useState(false)

  const { name } = useTheme()

  return (
    <>
      <FeaturesWrapper>
        <MainContent>
          <MainWrapper>
            {/* <Fade bottom delay={200}> */}
            <Heading>
              <h6>My recent projects</h6>

              {/* <Fade bottom delay={300}>
                <h1>Perfect for SMBs and enterprise</h1>
              </Fade>

              <Fade bottom delay={350}>
                <p>
                  Designed to help you achieve business goals with the right web
                  dev tools.
                </p>
              </Fade> */}
            </Heading>
            {/* </Fade> */}

            <CardWrapper>
              <Card
                title="HummmingBird Hammocks"
                description="Ultralight hammocks for camping and backpacking that are as tiny in your pack as they are on the scale. Hammocks, rain tarps, bug nets, tree straps, underquilts, and more designed to be as light and small as possible. Lighten your pack with Hummingbird Hammocks!"
                delay="50"
                link="https://hummingbirdhammocks.com/"
                status={[
                  { name: "ongoing", id: 1, background: "#b9cef9" },
                  { name: "eCommerce", id: 2, background: "#f9b9eb" },
                  { name: "React", id: 3, background: "#8ecde0" },
                  { name: "Gatsby", id: 4, background: "#1096fd" },
                  { name: "Shopify", id: 5, background: "#4baf7e" },
                  { name: "MUI", id: 6, background: "#e08e8e" },
                ]}
              >
                <StaticImage
                  src="../../images/projects/hummingbird-hammocks.png"
                  alt="Hummingbird Hammocks"
                  height={260}
                  layout="constrained"
                  placeholder="tracedSVG"
                  imgStyle={{ borderRadius: "7px" }}
                />
              </Card>
              <Card
                status={[
                  { name: "React", id: 1, background: "#e08e8e" },
                  { name: "Gatsby", id: 2, background: "#b9cef9" },
                  { name: "Gloriafood", id: 3, background: "#8ecde0" },
                  { name: "AWS", id: 4, background: "#4baf7e" },
                  {
                    name: "Styled Components",
                    id: 5,
                    background: "#f9b9eb",
                  },
                ]}
                title="Spruzzo Restaurant & Bar"
                description="Welcome to Spruzzo, a family-friendly Italian-American dining experience, newly opened in Pacific Palisades. We serve a variety of Italian and American dishes including pizza, pasta, salad, chicken, steak, and fresh seafood entrées. We are proud to be a family owned small business and look forward to serving the Palisades community for many years to come!"
                delay="200"
                link="https://www.spruzzopalisades.com"
              >
                <StaticImage
                  src="../../images/projects/spruzzopalisades.png"
                  alt="Spruzzo Restaurant & Bar"
                  height={260}
                  layout="constrained"
                  placeholder="tracedSVG"
                  imgStyle={{ borderRadius: "7px" }}
                />
              </Card>

              <Card
                status={[
                  { name: "React", id: 2, background: "#b9cef9" },
                  { name: "Gatsby", id: 2, background: "#1096fd" },
                  { name: "Gloriafood", id: 3, background: "#f9b9eb" },
                  { name: "AWS", id: 4, background: "#8ecde0" },
                  {
                    name: "Styled Components",
                    id: 5,
                    background: "#e08e8e",
                  },
                ]}
                title="Ribs USA BBQ"
                link="https://www.ribsusabbq.com/"
                description="Ribs USA where America eats ribs. Come have some the best bbq meats in Southern California."
                delay="200"
              >
                <StaticImage
                  src="../../images/projects/ribs-usa.png"
                  alt="Ribs USA BBQ"
                  height={260}
                  layout="constrained"
                  placeholder="tracedSVG"
                  imgStyle={{ borderRadius: "7px" }}
                />
              </Card>
              <Card
                link="https://www.codeforaustralia.org"
                status={[
                  { name: "agency", id: 1, background: "#b9cef9" },
                  { name: "React", id: 2, background: "#4baf7e" },
                  { name: "Gatsby", id: 3, background: "#f9b9eb" },
                  { name: "Craft", id: 4, background: "#e08e8e" },
                  { name: "Tailwind", id: 5, background: "#8ecde0" },
                ]}
                title="Code For Australia"
                description="We're on a mission to help government in Australia be more open, and work collaboratively to solve our most pressing issues."
                delay="200"
              >
                <StaticImage
                  src="../../images/projects/code-for-australia.png"
                  alt="Code For Australia"
                  height={260}
                  layout="constrained"
                  placeholder="tracedSVG"
                  imgStyle={{ borderRadius: "7px" }}
                />
              </Card>

              {load && (
                <>
                  <Card
                    status={[
                      { name: "React", id: 1, background: "#e08e8e" },
                      { name: "Gatsby", id: 2, background: "#b9cef9" },
                      { name: "AWS", id: 3, background: "#4baf7e" },
                      {
                        name: "Styled Components",
                        id: 4,
                        background: "#f9b9eb",
                      },
                    ]}
                    title="Manifest Your Therapy"
                    description="Hi, I’m Deborah. My wish is to support others in finding healing, peace and growth in becoming the best versions of themselves. We are all capable of so much and are consistently learning and evolving, yet we often forget that. We can easily get stuck in negative thought patterns and maladaptive behaviors."
                    delay="50"
                    link="https://www.manifestyourtherapy.com/"
                  >
                    <StaticImage
                      src="../../images/projects/manifest-your-therapy.png"
                      alt="Manifest Your Therapy"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                  <Card
                    status={[
                      { name: "React", id: 2, background: "#8ecde0" },
                      { name: "Gatsby", id: 2, background: "#e08e8e" },
                      { name: "Gloriafood", id: 3, background: "#b9cef9" },
                      { name: "AWS", id: 4, background: "#f9b9eb" },
                      {
                        name: "Styled Components",
                        id: 5,
                        background: "#1096fd",
                      },
                    ]}
                    title="Neighbarista"
                    link="https://www.neighbarista.com/"
                    description="Neighbarista serves unique breakfast and lunch dishes. We're passionate about making the best breakfast and memorable lunch. verything we serve is made in-house."
                    delay="50"
                  >
                    <StaticImage
                      src="../../images/projects/neighbarista.png"
                      alt="Loop Control"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                  <Card
                    status={[
                      { name: "React", id: 1, background: "#4baf7e" },
                      { name: "Gatsby", id: 2, background: "#b9cef9" },
                      { name: "Sanity", id: 3, background: "#8ecde0" },
                      { name: "Netlify", id: 4, background: "#f9b9eb" },
                      {
                        name: "Styled Components",
                        id: 5,
                        background: "#e08e8e",
                      },
                    ]}
                    title="Accentuate Agency"
                    description="Accentuate is a Digital Agency that specialises in Website Design, UX, UI, Website Development, SEO and Pay Per Click."
                    delay="50"
                    link="https://www.accentuate.agency/"
                  >
                    <StaticImage
                      src="../../images/projects/accentuate-agency.png"
                      alt="Accentuate Agency"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                  <Card
                    status={[
                      { name: "React", id: 1, background: "#f9b9eb" },
                      { name: "Next", id: 2, background: "#b9cef9" },
                      {
                        name: "Styled Components",
                        id: 3,
                        background: "#8ecde0",
                      },
                    ]}
                    link="https://www.cookware-co.com/"
                    title="The Cookware Company"
                    description="The Cookware Company is a global cookware manufacturer with worldwide brand presence. Starting in Belgium in 2007 with their original brand, GreenPan, The Cookware Company was the first to introduce PTFE-free non-stick cookware into the market."
                    delay="200"
                  >
                    <StaticImage
                      src="../../images/projects/cookware.png"
                      alt="The Cookware Company"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                </>
              )}
              {load1 && (
                <>
                  <Card
                    status={[
                      { name: "photography", id: 2, background: "#1096fd" },
                      { name: "React", id: 2, background: "#e08e8e" },
                      { name: "Gatsby", id: 3, background: "#b9cef9" },
                      { name: "Sanity", id: 4, background: "#8ecde0" },
                      { name: "Netlify", id: 5, background: "#4baf7e" },
                    ]}
                    title="Patricia Lopes"
                    link="https://www.patricialopes.be/"
                    description="Patricia Lopes is a Portuguese-Brazilian cinematographer based in Brussels & London, who shoots narrative films, commercials, music videos and documentary."
                    delay="50"
                  >
                    <StaticImage
                      src="../../images/projects/patricia-lopes.png"
                      alt="Patricia Lopes"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                  <Card
                    status={[
                      { name: "React", id: 2, background: "#b9cef9" },
                      { name: "Gatsby", id: 2, background: "#f9b9eb" },
                      { name: "Prismic", id: 3, background: "#8ecde0" },
                      {
                        name: "Styled Components",
                        id: 4,
                        background: "#e08e8e",
                      },
                    ]}
                    title="Loop Control"
                    link="https://loop-control.com/"
                    description="Take control of your life - Redefining automation to provide a complete solution for any type of device."
                    delay="200"
                  >
                    <StaticImage
                      src="../../images/projects/loop-control.png"
                      alt="Loop Control"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                  <Card
                    title="Frank's Weiler's Deli"
                    description="Frank's Weiler's Deli is one of Cano Parks tastiest family restaurants. There isn't anything we can't make to accomodate someone dietary preferences."
                    delay="50"
                    link="https://www.franksweilersdeli.com/"
                    status={[
                      { name: "ongoing", id: 1, background: "#1096fd" },
                      { name: "Restaurant", id: 2, background: "#4baf7e" },
                      { name: "Typescrript", id: 3, background: "#e08e8e" },
                      { name: "React", id: 4, background: "#b9cef9" },
                      { name: "Gatsby", id: 5, background: "#8ecde0" },
                      {
                        name: "Styled Components",
                        id: 6,
                        background: "#f9b9eb",
                      },
                    ]}
                  >
                    <StaticImage
                      src="../../images/projects/franks-weilers-deli.png"
                      alt="Frank's Weiler's Deli"
                      height={260}
                      layout="constrained"
                      placeholder="tracedSVG"
                      imgStyle={{ borderRadius: "7px" }}
                    />
                  </Card>
                </>
              )}
            </CardWrapper>

            {!load && (
              // <Fade bottom delay={20}>
              <ButtonWrap>
                <Button onClick={() => setLoad(true)}>Load More</Button>
              </ButtonWrap>
              // </Fade>
            )}

            {load && !load1 && (
              // <Fade bottom delay={20}>
              <ButtonWrap>
                <Button onClick={() => setLoad1(true)}>Load More</Button>
              </ButtonWrap>
              // </Fade>
            )}
          </MainWrapper>
        </MainContent>
        {/* {name === "dark" && <Footer />} */}
        {name === "light" && <Upper />}
      </FeaturesWrapper>

      {/* {name === "light" && <Footer />} */}
    </>
  )
}
