import styled from "styled-components"

export const ContactWrapper = styled.section`
  padding: 40px 0;
  background-color: ${({ theme }) => theme.contactBack};

  h6 {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.text};
  }
`

export const ContactStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContactFormContent = styled.form`
  min-width: 600px;

  @media (max-width: 992px) {
    min-width: 500px;
  }

  @media (max-width: 992px) {
    min-width: 400px;
  }

  @media (max-width: 992px) {
    min-width: 320px;
  }

  @media (max-width: 370px) {
    min-width: 270px;
  }

  @media (max-width: 370px) {
    min-width: inherit;
  }

  padding-top: 20px;
  font-size: var(--fontSizeThree);
  display: block;
  color: var(--black);

  input,
  textarea {
    background: ${({ theme }) => theme.contactBack};
    font-family: "Lato", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid rgb(233 227 227 / 97%);
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    box-shadow: none;
    outline: none;
    display: block;
    transition: 0.15s;
    padding: 10px 13px 9px 13px;
    margin: 1rem 0 7px 0;
    border-radius: 6px;
    width: 100%;
    color: ${({ theme }) => theme.text};

    @media (max-width: 992px) {
      border: 1px solid rgb(156 156 156 / 30%);
    }

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.inputColor};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.inputColor};
    }
  }

  span {
    font-size: 13px;
    margin-left: 10px;
    color: white;
  }
  textarea {
    width: 100%;
  }
  .up-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    div {
      display: block;
    }

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 00px;
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
`
