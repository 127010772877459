import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
// import { Fade } from "react-reveal"

import { AnchorLinkStyled } from "components"

const FooterWrapper = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.contactBack};
  padding: 50px 20px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;

  > div {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.text};

    .home {
      color: ${({ theme }) => theme.text};
    }
  }

  > p {
    text-align: center;
    color: ${({ theme }) => theme.text};

    a {
      color: ${({ theme }) => theme.footerA};
    }
  }
`

const Footer = () => {
  return (
    <FooterWrapper>
      {/* <Fade bottom delay="100"> */}
      <div>
        Copyright © 2023 All Rights Reserved by{" "}
        <Link className="home" to="/">
          thejahid.com
        </Link>{" "}
        |&nbsp;
        <AnchorLinkStyled fontSize="14px" padding="0" to="/#contact">
          Contact
        </AnchorLinkStyled>
      </div>
      {/* </Fade>

      <Fade bottom delay="130"> */}{" "}
      <p>
        Designed by <a href="mailto:thejahid@icloud.com">@thejahid.</a>
      </p>
      {/* </Fade> */}
    </FooterWrapper>
  )
}

export default Footer
