import { createGlobalStyle } from "styled-components"

import gradientImage from "../../images/hero-gradiant.jpeg"
import gradientImage2 from "../../images/hero-gradiant-2.jpg"
import darkImage from "../../images/hero-dark.jpg"

export const darkTheme = {
  name: "dark",
  body: "#0f0818cc",
  text: "#ffffffe6",
  font: "#D1D1D1",
  background: "#ffffffe6",
  backgroundText: "#000",
  heroImage: darkImage,
  heroImage2: darkImage,
  // contactBack: "rgb(255 255 255 / 9%)",
  contactBack: "#000",
  // inputColor: "#ffffff96",
  inputColor: "#757575",
  footerA: "#ffffff9e",
  navA: "#b9b9b9",
  cardP: "#f7f7f7",
}

export const lightTheme = {
  name: "light",
  body: "#FFFFFF",
  text: "#000",
  font: "#2A292E",
  background: "#000",
  backgroundText: "#ffffffe6",
  heroImage: gradientImage,
  heroImage2: gradientImage2,
  contactBack: "rgb(255 255 255 / 44%)",
  inputColor: "#757575",
  footerA: "#0000008f",
  navA: "#625959",
  cardP: "#000000a8",
}

export const GlobalStyles = createGlobalStyle`
  :root {
   /* ------------------------- Font------------------------- */
    --fontPrimary: 'Jost', sans-serif;

    /* ------------------------- box shadow & border ------------------------- */
    --navbarBoxShadow: 0px 2px 12px #0000001D;
    --imageBorderRadius: 20px;

    /* ------------------------- font size ------------------------- */
    /*  common font size  */
    --commonTitle: 20px;

     /* ------------------------- font weight ------------------------- */
    --normal: 400;
    --semiBold: 400;
    --bold: 500;
     /* ------------------------- transition ------------------------- */
     --defaultTransition: 0.3s;
  }
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body{
    font-family: var(--fontPrimary);
    font-weight: var(--normal);
    line-height: 1;
  }
    ol, ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    display: inline-block;
  }`
