import React, { useState } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { Link } from "gatsby"

import { MainWrapper } from "components"

const NavBar = styled.nav`
  position: absolute;
  width: 100%;
  z-index: 999;
`

const MainContent = styled.div`
  padding: 50px 100px 100px 0;

  @media (max-width: 992px) {
    padding: 50px 0 20px 0;
  }
`

const NavContent = styled.div`
  display: flex;
  justify-content: right;

  @media (max-width: 992px) {
    display: none;
  }
`

const StyledLink = styled(AnchorLink)`
  padding: 6px 20px;
  z-index: 101;
  color: rgb(185, 185, 185);
  font-size: 15px;

  :hover {
    color: white;
  }
`

const GatsbyStyledLink = styled(Link)`
  padding: 5px 20px;
  z-index: 101;
  border-radius: 5px;
  transition: all 0.3s;
  border: ${({ border }) => (border ? "1px solid rgb(255 255 255 / 29%)" : "")};
  margin-left: 10px;
  font-size: 15px;
  color: rgb(185, 185, 185);

  :hover {
    background-color: rgb(0 0 0 / 20%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 40%);
    color: white;
  }
`

const StyledMenu = styled.div`
  display: none;

  @media (max-width: 992px) {
    position: absolute;
    top: 5%;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`

const StyledRight = styled.div`
  display: flex;
  justify-content: end;
`

const StyledBurger = styled.div`
  width: 40px;
  background: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  border-radius: 8px;
  transition: all 0.3s;
  height: 43px;
  position: fixed;
  top: 20px;

  .btn {
    padding: 16px 8px;
    border-radius: 8px;
  }

  &:focus {
    outline: none;
  }

  .line {
    width: 23px;
    height: 3px;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "0")};
  }

  .large {
    margin-top: ${({ open }) => (open ? "4px" : "0")};
  }

  .small {
    width: ${({ open }) => (open ? "23px" : "15px")};
    margin-left: ${({ open }) => (open ? "0" : "7px")};
    margin-top: ${({ open }) => (open ? "-3px" : "5px")};
    transform: ${({ open }) => (open ? "rotate(45deg)" : "0")};
  }
`

const Menu = styled.div`
  min-width: 200px;
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 15px;
  color: #000;
  display: grid;
  z-index: 999;
  position: fixed;
  right: 20px;
  top: 75px;

  a {
    color: black;
    padding: 12px 10px;
    margin: 0;

    :hover {
      background-color: inherit;
      box-shadow: none;
      color: #111;
    }
  }
`

const Nav = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <NavBar>
      <MainContent>
        <MainWrapper>
          <NavContent>
            <StyledLink to="/#services">My services</StyledLink>
            <GatsbyStyledLink border to="https://calendly.com/thejahid">
              Schedule a meeting
            </GatsbyStyledLink>
            <StyledLink to="/#contact">Contact</StyledLink>

            {children}
          </NavContent>
          <StyledMenu>
            <StyledRight>
              <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div className="btn">
                  <div className="line large" />
                  <div className="line small" />
                </div>
              </StyledBurger>
            </StyledRight>

            {open && (
              <Menu>
                <StyledLink onClick={() => setOpen(!open)} to="/#services">
                  My services
                </StyledLink>
                <GatsbyStyledLink border to="https://calendly.com/thejahid">
                  Schedule a meeting
                </GatsbyStyledLink>
                <StyledLink onClick={() => setOpen(!open)} to="/#contact">
                  Contact
                </StyledLink>
              </Menu>
            )}
          </StyledMenu>
        </MainWrapper>
      </MainContent>
    </NavBar>
  )
}

export default Nav
