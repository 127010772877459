/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"

import { MainWrapper, Spinner, OnClickButton } from "components"

import { ContactWrapper, ContactFormContent, ContactStyle } from "./styles"

export const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSubmit = async ({ name, email, message }, e) => {
    setLoading(true)

    await axios
      .post("https://dm2msyt9n5.execute-api.us-east-1.amazonaws.com/final/", {
        name,
        email,
        message,
        destination: "thejahid@icloud.com",
      })
      .then(res => {
        setMessage("Thank you! I'll be in touch soon!")
        e.target.reset()
      })
      .catch(err => setMessage("Something went wrong!"))

    setLoading(false)
  }

  return (
    <ContactWrapper id="contact">
      <MainWrapper>
        <h6>Send me a message and I'll get right back to you.</h6>

        <ContactStyle>
          <ContactFormContent onSubmit={handleSubmit(onSubmit)}>
            <div className="up-form">
              <div>
                <input
                  placeholder="Full Name"
                  {...register("fullName", { required: true, maxLength: 50 })}
                />

                {errors.fullName?.type === "required" && (
                  <span>Name is required!</span>
                )}
              </div>
              <div>
                <input
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
                {errors.email?.type === "required" && (
                  <span>Email is required!</span>
                )}
              </div>
            </div>

            <div>
              <textarea
                rows="6"
                placeholder="Message"
                {...register("message", { required: true })}
              />
              {errors.message?.type === "required" && (
                <span>Message is required!</span>
              )}
            </div>
            {message ? <span>{message}</span> : ""}

            <div className="submit">
              <OnClickButton disabled={loading} type="submit">
                {loading ? <Spinner /> : "Send Your Message"}
              </OnClickButton>
            </div>
          </ContactFormContent>
        </ContactStyle>
      </MainWrapper>
    </ContactWrapper>
  )
}
