import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { MainWrapper } from "components"

const NavBar = styled.header`
  color: ${({ theme }) => theme.text};
  transition: 0.3s;
  position: relative;
  background: #0f0818f2;

  &:before {
    background-image: ${({ theme }) => `url(${theme.heroImage})`};
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
  }

  a {
    color: ${({ theme }) => theme.navA};

    :hover {
      color: #ffffffe6;
    }
  }
`

const MainContent = styled.div`
  padding: 50px 100px 100px 0;

  @media (max-width: 992px) {
    padding: 50px 0 20px 0;
  }
`

const DetailsWrapper = styled.div`
  margin-top: 450px;
`

const Details = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

const MyDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    min-width: 600px;
  }

  @media (max-width: 992px) {
    min-width: 450px;
  }

  @media (max-width: 450px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    min-width: 270px;
  }
`

const DetailsContent = styled.div`
  margin-left: 30px;

  @media (max-width: 450px) {
    margin-left: 0;
  }

  > h1 {
    font-size: 20px;
    padding-bottom: 10px;
    z-index: 110;
  }

  .react-reveal {
    display: flex;
  }
`

const SocialIcon = styled.div`
  display: flex;
  margin-top: 15px;

  svg {
    width: 18px;
  }

  a {
    margin-right: 15px;
  }

  path {
    fill: ${({ theme }) => theme.text};
    transition: 0.3s;

    :hover {
      fill: white;
    }
  }
`

const WhiteGradient = styled.div`
  background-image: ${({ theme }) =>
    theme.name === "light"
      ? `linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.02) 42%,
    #fff
  )`
      : ""};
  bottom: 0;
  height: ${({ theme }) => (theme.name === "light" ? "200px" : "130px")};
  width: 100%;
`

export const Header = () => {
  return (
    <NavBar>
      <MainContent>
        <MainWrapper>
          <DetailsWrapper>
            <Details>
              <MyDetails>
                {/* <Fade bottom delay="50"> */}
                <StaticImage
                  src="../../images/thejahid.jpg"
                  alt="Jahid Hasan"
                  layout="fixed"
                  height={170}
                  placeholder="tracedSVG"
                  imgStyle={{ borderRadius: "10px" }}
                />
                {/* </Fade> */}

                <DetailsContent>
                  {/* <Fade bottom delay="50"> */}
                  <h1>Jahid Hasan</h1>
                  <span>
                    {/* <Fade bottom delay="50"> */}
                    <a href="mailto:thejahid@icloud.com">@thejahid</a> .
                    Software Developer
                    {/* </Fade> */}
                  </span>
                  {/* </Fade> */}

                  {/* <Fade bottom delay="50"> */}
                  <SocialIcon>
                    {/* <Fade bottom delay="50"> */}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://github.com/thejahid"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="17"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.8 0A8.7 8.7 0 006 17c.4 0 .6-.2.6-.4v-1.7c-2.2.4-2.8-.5-3-1 0-.2-.5-1-.9-1.2-.3-.2-.7-.6 0-.6s1.2.6 1.4 1c.8 1.2 2 .9 2.5.6 0-.5.3-1 .6-1.1-2-.3-4-1-4-4.3 0-1 .3-1.8.9-2.4-.1-.2-.4-1.1 0-2.3 0 0 .8-.2 2.5.9a8.1 8.1 0 014.4 0c1.6-1.1 2.4-.9 2.4-.9.4 1.2.1 2.1 0 2.3.6.6 1 1.4 1 2.4 0 3.3-2.1 4-4 4.3.3.2.5.8.5 1.6v2.4c0 .2.2.5.6.4A8.7 8.7 0 008.7 0z"
                          fill="#1B1F23"
                        ></path>
                      </svg>
                    </a>
                    {/* </Fade> */}
                    {/* <Fade bottom delay="90"> */}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/thejahid/"
                    >
                      <svg
                        width="17"
                        height="17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 1.5a61.1 61.1 0 015 .4c.4.1.7.3 1 .6.3.3.5.6.6 1 .1.3.3.7.3 1.6a61.2 61.2 0 01-.3 8.4c-.1.4-.3.7-.6 1-.3.3-.6.5-1 .6-.3.1-.7.3-1.6.3a61.1 61.1 0 01-8.4-.3c-.4-.1-.7-.3-1-.6-.3-.3-.5-.6-.6-1-.1-.3-.3-.7-.3-1.6a61.2 61.2 0 01.3-8.4c.1-.4.3-.7.6-1 .3-.3.6-.5 1-.6.3-.1.7-.3 1.6-.3h3.4zm0-1.5A55 55 0 005 0C4 0 3.5.3 3 .5c-.6.3-1.1.5-1.6 1L.4 3A54.7 54.7 0 00.4 14c.3.6.5 1.1 1 1.6l1.5 1c.6.2 1.2.3 2.1.3l3.5.1H12c1 0 1.5-.2 2-.4.6-.3 1.1-.5 1.6-1l1-1.5c.2-.6.3-1.2.3-2.1l.1-3.5V5c0-1-.2-1.5-.4-2-.3-.6-.5-1.1-1-1.6s-1-.7-1.5-1A54.7 54.7 0 008.5 0zm0 4.1a4.4 4.4 0 100 8.8 4.4 4.4 0 000-8.8zm0 7.2a2.8 2.8 0 110-5.6 2.8 2.8 0 010 5.6zM14 4c0 .5-.4 1-1 1a1 1 0 010-2c.6 0 1 .4 1 1z"
                          fill="#000"
                        ></path>
                      </svg>
                    </a>
                    {/* </Fade> */}
                    {/* <Fade bottom delay="120"> */}
                    <a href="mailto:thejahid@icloud.com">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M448 64H64C28.65 64 0 92.65 0 128v256c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V128C512 92.65 483.3 64 448 64zM64 112h384c8.822 0 16 7.178 16 16v22.16l-166.8 138.1c-23.19 19.28-59.34 19.27-82.47 .0156L48 150.2V128C48 119.2 55.18 112 64 112zM448 400H64c-8.822 0-16-7.178-16-16V212.7l136.1 113.4C204.3 342.8 229.8 352 256 352s51.75-9.188 71.97-25.98L464 212.7V384C464 392.8 456.8 400 448 400z" />
                      </svg>
                    </a>
                    {/* </Fade> */}
                  </SocialIcon>
                  {/* </Fade> */}
                </DetailsContent>
              </MyDetails>
            </Details>
          </DetailsWrapper>
        </MainWrapper>
      </MainContent>

      <WhiteGradient />
    </NavBar>
  )
}

export default Header
